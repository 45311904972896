import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import {
  textFieldSx,
  toggleButtonSx,
  toggleContainerSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  image,
  settingsElement,
  additionalSettings,
  toggle,
} from "../style/pages/acryliccylinders.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import { StoreContext } from "../context/store-context";

function Acryliccylinders() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "acrylicfalsebeads/cylinders" }
          name: { nin: ["main"] }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [size, setSize] = useState("");
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  const dataCylinders = [
    {
      size: "15см/12см",
      cost: 1800,
    },
    {
      size: "20см/12см",
      cost: 2100,
    },
    {
      size: "25см/12см",
      cost: 2650,
    },
    {
      size: "30см/12см",
      cost: 3200,
    },
  ];

  // Обновить состояние для размера
  const handleSize = (event, newAlignment) => {
    setSize(newAlignment);
    if (newAlignment) {
      dataCylinders
        .filter((item) => item.size === newAlignment)
        .forEach((item) => setCost(item.cost));
    } else {
      setCost(0);
    }
  };

  // Обновить состояние для количества
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Добавить набор
  const addProduts = () => {
    const dataOrder = {
      size: size,
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      acrylicfalsebeads: {
        cylinders: {
          bundle: [...store.acrylicfalsebeads.cylinders.bundle, dataOrder],
          totalPrice:
            store.acrylicfalsebeads.cylinders.totalPrice +
            dataOrder.totalAmount,
        },
        crystal: { ...store.acrylicfalsebeads.crystal },
        octahedron: { ...store.acrylicfalsebeads.octahedron },
      },
      frame: { ...store.frame },
      stand: { ...store.stand },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      substrates: { ...store.substrates },
    });

    setSize("");
    setCost(0);
    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.acrylicfalsebeads.cylinders.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount =
      store.acrylicfalsebeads.cylinders.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      acrylicfalsebeads: {
        cylinders: {
          bundle: [...arrBundle],
          totalPrice:
            store.acrylicfalsebeads.cylinders.totalPrice - deductibleAmount,
        },
        crystal: { ...store.acrylicfalsebeads.crystal },
        octahedron: { ...store.acrylicfalsebeads.octahedron },
      },
      frame: { ...store.frame },
      stand: { ...store.stand },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      substrates: { ...store.substrates },
    });
  };

  useEffect(() => {
    let q = quantity || 0;
    let c = cost || 0;

    setTotalAmount(q * c);
  }, [quantity, cost]);

  return (
    <main className="mainpage">
      <MetaTag title="Цилиндры из акрила | Wowmilota" />
      <motion.div
        layoutId="cylindersContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img, index) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.995 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотография цилиндра из акрила"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <div className={detailHeader}>
                  <h2>Цилиндры из акрила</h2>
                </div>
                <p>
                  Цилиндры из акрила добавляют объёма, высоты, воздушности
                  торту. Такие межъярусы можно обыграть как угодно: поместить
                  живые цветы в фальшярус, добавить гирлянду, обыграть ярус
                  искусственным кристаллами, расписать его водными красками,
                  сделать внутри композицию из фигур в тематике мероприятия.
                  <br />
                  <br />
                  Важно помнить, что акрил нельзя обрабатывать спиртосодержащими
                  и грубыми абразивными средства. Достаточно помыть мягкой
                  тряпочкой в мыльном растворе воды.
                </p>
              </div>

              <h3 className={titleSettings}>Обязательные параметры</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: size
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Размер (см)</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={size}
                    exclusive
                    onChange={handleSize}
                    sx={toggleContainerSx}
                  >
                    {dataCylinders.map((item) => {
                      const { size } = item;

                      return (
                        <ToggleButton
                          value={size}
                          key={`size-${size}`}
                          sx={toggleButtonSx}
                        >
                          {size}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="cakeQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Характеристики</h3>

              <div className={additionalSettings}>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Материал</h3>
                  <p>Акрил</p>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.acrylicfalsebeads.cylinders.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.acrylicfalsebeads.cylinders.bundle.map(
                    (item, index) => {
                      return (
                        <div key={`order_${index}`} className={productItem}>
                          <button
                            className={delProduct}
                            aria-label="Удалить"
                            onClick={() => deleteProduct(index)}
                          >
                            <MdOutlineClose color="white" />
                          </button>
                          <h3>Набор {index + 1}</h3>
                          <p>Размер: {item.size}</p>
                          <p>{item.quantity} шт.</p>
                          <p>{item.totalAmount} руб.</p>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>

            <div className={priceInfo}>
              <div className={mobileProducts}>
                <button onClick={() => setMobileList(!mobileList)}>
                  <MdShoppingBasket color="white" />
                  <p className={nubmer}>
                    {store?.acrylicfalsebeads.cylinders.bundle.length}
                  </p>
                </button>
                {mobileList && (
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    grabCursor={true}
                    className={productContainer}
                  >
                    {store?.acrylicfalsebeads.cylinders.bundle.map(
                      (item, index) => {
                        return (
                          <SwiperSlide
                            key={`order_${index}`}
                            className={productItem}
                          >
                            <button
                              className="delProduct"
                              aria-label="Удалить"
                              onClick={() => deleteProduct(index)}
                            >
                              <MdOutlineClose color="white" />
                            </button>
                            <h3>Набор {index + 1}</h3>
                            <p>Размер: {item.size}</p>
                            <p>{item.quantity} шт.</p>
                            <p>{item.totalAmount} руб.</p>
                          </SwiperSlide>
                        );
                      }
                    )}
                  </Swiper>
                )}
              </div>
              {totalAmount > 0 ? (
                <button className={costBundle} onClick={addProduts}>
                  {totalAmount > 0
                    ? `Добавить набор ${totalAmount} руб.`
                    : "Сформируйте набор"}
                </button>
              ) : (
                <p className={costBundle}>Сформируйте набор</p>
              )}
              <Link to="/" className={mobCloseBtn}>
                <MdOutlineClose color="white" />
              </Link>
              <Link to="/" className={closeBtn}>
                {store?.stand.cake.bundle.length > 0
                  ? `Сохранить и закрыть`
                  : `закрыть`}
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Acryliccylinders;
